import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DateValue } from '@vakantiesnl/components/src/atoms/DatePicker/DatePicker.types';
import { Drawer } from '@vakantiesnl/components/src/atoms/Drawer';
import { Icon } from '@vakantiesnl/components/src/atoms/Icon';
import { InputAdornmentComponent } from '@vakantiesnl/components/src/atoms/InputAdornment';
import { PopperContainer } from '@vakantiesnl/components/src/atoms/PopperContainer/PopperContainer';
import { useBreakpoints } from '@vakantiesnl/components/src/utils/useBreakpoints';
import { useMicroCopyContext } from '@vakantiesnl/services/src/context/microCopyContext';
import { getDateDMY, getDateYMD, parseToYMDDate } from '@vakantiesnl/services/src/util/dateUtils';
import dynamic from 'next/dynamic';

import { useCustomStyles } from './SelectFormFieldCalendar.style';
import { TextFieldComponent, TextfieldComponentProps } from '../../TextField';

const InlineDatePicker = dynamic(
	import('@vakantiesnl/components/src/atoms/DatePicker').then((mod) => mod.DatePicker),
	{
		ssr: false,
	},
);

export type Props = {
	departureDate: string[] | null;
	setDepartureDate: (date: string[]) => void;
	flexDays: number | boolean;
	setFlexDays: (isFlexible: number | boolean) => void;
	hasFlexibilityOptions: boolean;
	hasDrawerOnMobileTablet: boolean;
	inputLabel?: string;
	dataCy?: string;
	onOpenCalender?: () => void;
};

export const SelectFormFieldCalendar: React.FC<Props> = ({
	setDepartureDate,
	departureDate,
	flexDays,
	setFlexDays,
	dataCy,
	hasDrawerOnMobileTablet,
	hasFlexibilityOptions,
	inputLabel,
	onOpenCalender,
}) => {
	const microCopies = useMicroCopyContext();

	const { classes } = useCustomStyles();
	const { isDesktop } = useBreakpoints();

	const inputFieldRef = useRef(null);
	const initialDate: DateValue = getInitialDate(departureDate, flexDays);

	// Keep track of date changes internally, so we can apply only when calendar is closed
	const [tempDate, setTempDate] = useState(initialDate);
	const [isCalenderVisible, setCalenderVisible] = useState(false);

	useEffect(() => {
		// Reset value when navigating between pages (component does not always unmount)
		setTempDate(getInitialDate(departureDate, flexDays));
	}, [departureDate, flexDays]);

	const closeCalender = useCallback(() => {
		if (tempDate.mode === 'single' && tempDate.value?.length === 1) {
			setDepartureDate(tempDate.value.map(getDateYMD));
			setFlexDays(tempDate.flexDays);
		} else if (tempDate.mode === 'range' && tempDate.value.length === 2) {
			setDepartureDate(tempDate.value.map(getDateYMD));
			setFlexDays(0);
		} else {
			setTempDate(initialDate);
		}

		setCalenderVisible(false);
	}, [initialDate, setDepartureDate, setFlexDays, tempDate]);

	const closeCalenderWithoutSaving = useCallback(() => {
		setTempDate(initialDate);
		setCalenderVisible(false);
	}, [initialDate]);

	const toggleCalenderVisible = useCallback(() => {
		if (!isCalenderVisible) {
			onOpenCalender?.();
			setCalenderVisible(true);
		} else {
			closeCalender();
		}
	}, [isCalenderVisible, closeCalender, onOpenCalender]);

	const InputProps = useMemo(() => {
		return { ...getDateInputFieldOptions(isCalenderVisible), className: classes.inputField };
	}, [classes.inputField, isCalenderVisible]);

	const submitButtonProps = useMemo(
		() => ({
			isDisabled: getIsSubmitButtonDisabled(initialDate, tempDate),
			onSubmit: closeCalender,
			label: microCopies['common.apply'],
		}),
		[closeCalender, initialDate, microCopies, tempDate],
	);

	const hasPopper = !hasDrawerOnMobileTablet || isDesktop;

	const onSelectDate = useCallback(
		(value: DateValue) => {
			setTempDate(value);

			// Only close right away when in 'single' mode a new date is selected
			if (
				value.mode === 'single' &&
				value.value.length === 1 &&
				tempDate.value.join('') !== value.value.join('')
			) {
				setDepartureDate(value.value.map(getDateYMD));
				setCalenderVisible(false);
			}
		},
		[setDepartureDate, tempDate],
	);

	return (
		<div data-cy={dataCy}>
			<TextFieldComponent
				value={tempDate.value.map(getDateDMY).join(' - ')}
				ref={inputFieldRef}
				InputProps={InputProps}
				onClick={toggleCalenderVisible}
				label={inputLabel}
				placeholder={microCopies['filters.departureDatePlaceholder']}
			/>

			{isCalenderVisible && hasPopper && (
				<PopperContainer onClose={closeCalender} popperRef={inputFieldRef.current} className={classes.popper}>
					<InlineDatePicker
						hasFlexibilityOptions={hasFlexibilityOptions}
						onSelectDate={onSelectDate}
						selectedValue={tempDate}
						initialValue={initialDate}
					/>
				</PopperContainer>
			)}

			{isCalenderVisible && !hasPopper && (
				<Drawer
					contentClassName={classes.drawer}
					open={true}
					onDrawerClose={closeCalenderWithoutSaving}
					direction="up"
					hasCloseIcon={false}
					title={microCopies['filters.departureDate']}
					hasBackButton={true}
					submitButtonProps={submitButtonProps}
					isFullHeight={true}
				>
					<InlineDatePicker
						hasFlexibilityOptions={hasFlexibilityOptions}
						onSelectDate={setTempDate}
						selectedValue={tempDate}
						initialValue={initialDate}
					/>
				</Drawer>
			)}
		</div>
	);
};

function getIsSubmitButtonDisabled(initialDate: DateValue, tempDate: DateValue): boolean {
	if (!tempDate.value.length) {
		return true;
	}

	if (tempDate.mode === 'range' && tempDate.value.length === 2) {
		return tempDate.value.map(getDateYMD).join('') === initialDate.value.map(getDateYMD).join('');
	}

	if (tempDate.mode === 'single' && tempDate.value.length === 1) {
		return tempDate.value.map(getDateYMD).join('') === initialDate.value.map(getDateYMD).join('');
	}

	return true;
}

function getInitialDate(departureDate: string[] | null, flexDays: number | boolean): DateValue {
	if (departureDate?.length === 2) {
		return { mode: 'range', value: departureDate.map((d) => parseToYMDDate(d).toDate()) };
	}

	const parsedFlexDays = typeof flexDays === 'number' ? flexDays : flexDays === true ? 2 : 0;
	const parsedDepartureDate = (departureDate || []).map((d) => parseToYMDDate(d).toDate());

	return { mode: 'single', value: parsedDepartureDate, flexDays: parsedFlexDays };
}

function getDateInputFieldOptions(isExpanded: boolean): TextfieldComponentProps['InputProps'] {
	const options = {
		readOnly: true,
		'data-input': true /** Needed because of Flatpickr Wrap */,
		startAdornment: (
			<InputAdornmentComponent position="start" type="icon">
				<Icon type="calendar" />
			</InputAdornmentComponent>
		),
		endAdornment: (
			<InputAdornmentComponent position="end" type="icon">
				<Icon type="chevronDown" />
			</InputAdornmentComponent>
		),
		'data-cy': 'flatpickrInput',
		'aria-expanded': isExpanded,
		role: 'combobox',
	};

	return options;
}
